import { render, staticRenderFns } from "./SubsidySchedule.vue?vue&type=template&id=1536d937&scoped=true&"
import script from "./SubsidySchedule.vue?vue&type=script&lang=ts&"
export * from "./SubsidySchedule.vue?vue&type=script&lang=ts&"
import style0 from "./SubsidySchedule.vue?vue&type=style&index=0&id=1536d937&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1536d937",
  null
  
)

export default component.exports
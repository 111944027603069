








































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Demand, Supply } from "@/store/models/DemandAndSupply";
import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  MetaStore,
  JWTStore,
  UserStore,
  DSADStore
} from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { convertADtoBS, nepaliDate } from "@/store/modules/dateConverter";
import UserDetailDocument from "@/views/Admin/Components/UserDetailDocument.vue";
import { SubsidyDocument } from "@/store/models/crop";
import { roleChecker } from "@/utils/jwt";

import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component({
  components: {
    UserDetailDocument
  },
  metaInfo(): MetaInfo {
    return {
      title: "Supply List",
      meta: [
        { name: "keyword", content: "Sqcc demand list" },
        {
          name: "description",
          content: "List of Demands of seeds",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchCompany: string | null = null;
  searchCode: string | null = null;
  fiscalYearId: number | null = null;
  cropId: number | null = null;
  cropVarietyId: number | null = null;
  seedTypeId: number | null = null;
  districtId: number | null = null;
  pageNum: number = 1;
  clusterId: number = 0;
  language: string = "";
  loading: boolean = false;
  status: string | null = null;
  subsidyStatus: string | null = null;
  role: string = "";
  dialogVisible: boolean = false;
  documentVisible: boolean = false;
  documentLoading: boolean = false;
  currentData: Supply = {} as Supply;

  get dataList() {
    return DemandSupplyStore.PaginatedSupply;
  }

  unitName: any = {
    pkg: `${this.$t("per")} ${this.$t("kg")}`,
    pcent: `${this.$t("per")} %`
  };

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  // get akcDataList(){
  //   let paginatedSupply = DemandSupplyStore.PaginatedSupply
  //   let suppliers = paginatedSupply.results.filter(x => x.company_district == this.user.district )
  //   console.log("supsakc -> ",suppliers)
  //   return suppliers
  // }

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return this.nepaliUnicodeNumber(i);
  }

  nepaliDate(date: string) {
    return nepaliDate(date, this.$i18n.locale);
  }

  get districts() {
    return MetaStore.District;
  }

  get fiscalyears() {
    return MetaStore.FiscalYear;
  }

  get crops() {
    let crops = DemandSupplyStore.FyWiseSubsidyCrops.map(x => {
      return { fiscal_year: x.fiscal_year, name: x.crop_name, id: x.crop_type };
    });
    if (this.fiscalYearId) {
      crops = crops.filter(x => x.fiscal_year == this.fiscalYearId);
    }
    crops = [...new Map(crops.map(item => [item["id"], item])).values()];
    return crops;
  }

  get cropVarieties() {
    let cropVarieties = DemandSupplyStore.FyWiseSubsidy.map(x => {
      return {
        fiscal_year: x.fiscal_year,
        crop_type: x.crop_type,
        name: x.variety_name,
        id: x.crop_variety
      };
    });
    if (this.fiscalYearId || this.cropId) {
      if (this.fiscalYearId) {
        cropVarieties = cropVarieties.filter(x => x.fiscal_year == this.fiscalYearId);
      }
      if (this.cropId) {
        cropVarieties = cropVarieties.filter(x => x.crop_type == this.cropId);
      }
    }
    // Returns only unique variety
    cropVarieties = [...new Map(cropVarieties.map(item => [item["id"], item])).values()];
    return cropVarieties;
  }

  get seedTypes() {
    let seedTypes = DemandSupplyStore.SeedTypeList;
    if (this.roleChecker(["source_seed_producer", "SSSMAdmin"])) {
      seedTypes = seedTypes.filter(x => [2, 3].includes(x.id));
    }
    return seedTypes;
  }

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  get user() {
    console.log("user", UserStore.User);
    return UserStore.User;
  }

  changeCrop() {
    this.cropVarietyId = null;
  }

  thousandSeperator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  search() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    // if (this.fiscalYearId != null) {
    //   query["fiscalYearId"] = String(this.fiscalYearId);
    // }
    // if (this.cropId != null) {
    //   query["cropId"] = String(this.cropId);
    // }
    // if (this.cropVarietyId != null) {
    //   query["cropVarietyId"] = String(this.cropVarietyId);
    // }
    // if (this.seedTypeId != null) {
    //   query["seedTypeId"] = String(this.seedTypeId);
    // }
    // if (this.districtId != null) {
    //   query["districtId"] = String(this.districtId);
    // }
    // if (this.status != null) {
    //   query["status"] = String(this.status);
    // }
    // if (this.searchCompany != null) {
    //   query["searchCompany"] = String(this.searchCompany);
    // }
    // if (this.searchCode != null) {
    //   query["searchCode"] = String(this.searchCode);
    // }
    query = this.routerReplacer();
    DemandSupplyStore.getAdminSupplies(query);
  }

  clearSearch() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    this.fiscalYearId = null;
    this.cropId = null;
    this.cropVarietyId = null;
    this.seedTypeId = null;
    this.districtId = null;
    this.status = null;
    this.searchCode = null;
    this.searchCompany = null;
    query = this.routerReplacer();
    DemandSupplyStore.getAdminSupplies(query);
  }

  routerReplacer() {
    let query: any = {};
    if (this.pageNum) {
      query["page"] = String(this.pageNum);
    }
    if (this.fiscalYearId != null) {
      query["fiscalYearId"] = String(this.fiscalYearId);
    }
    if (this.cropId != null) {
      query["cropId"] = String(this.cropId);
    }
    if (this.cropVarietyId != null) {
      query["cropVarietyId"] = String(this.cropVarietyId);
    }
    // if role is ISMAdmin (Improved Seed Manager Admin), then pass seedTypeId = 4 (id of seedtype 'Improved Seed' )
    if (this.role == "ISMAdmin") {
      query["seedTypeId"] = String(4);
    } else if (this.seedTypeId != null) {
      query["seedTypeId"] = String(this.seedTypeId);
    }
    if (this.districtId != null) {
      query["districtId"] = String(this.districtId);
    }
    if (this.subsidyStatus != null) {
      query["subsidyStatus"] = String(this.subsidyStatus);
    }
    if (this.searchCompany != null) {
      query["searchCompany"] = String(this.searchCompany);
    }
    if (this.searchCode != null) {
      query["searchCode"] = String(this.searchCode);
    }
    if (this.$i18n.locale != null) {
      query["lang"] = String(this.$i18n.locale);
    }
    query["salesDetail"] = true;
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: query
        })
        .catch(error => {});
    }
    return query;
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    let query = this.routerReplacer();
    await DemandSupplyStore.getAdminSupplies(query);
    this.loading = false;
  }

  toogleExpand(row: any) {
    let $table = this.$refs.dataTable as any;
    $table.toggleRowExpansion(row);
  }

  viewBill() {
    this.dialogVisible = true;
  }

  get subsidyDocument() {
    return DemandSupplyStore.CurrentSubsidyDocument;
  }

  async showDocument(row: Supply) {
    this.documentVisible = true;
    this.documentLoading = true;
    this.currentData = row as any;
    if (this.currentData.supply_proof) {
      (this.currentData.supply_proof as any) = [
        {
          mimetype: "application/pdf",
          name: String(this.currentData.supply_proof),
          url: String(this.currentData.supply_proof)
        }
      ];
    }
    await DemandSupplyStore.getSubsidyDocument({ subsidyBatchId: row.subsidy_batch });
    this.documentLoading = false;
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  async approveSubsidy() {
    const isValid = await this.$validator.validateAll("sales-bill-form");
    if (isValid) {
      const data = {
        id: this.currentData.id,
        verified_quantity: this.currentData.verified_quantity
      };
      DemandSupplyStore.approveSalesDetail({ data: data, id: this.currentData.id }).then(() => {
        this.documentVisible = false;
      });
      this.search();
      this.$notify({
        title: this.$t("subsidyApproved").toString(),
        message: `${this.$t("subsidyApprovedMessage")}`,
        type: "success"
      });
    }
  }

  async rejectSubsidy() {
    const isValid = await this.$validator.validateAll("sales-bill-form");
    if (isValid) {
      const data = {
        id: this.currentData.id,
        verified_quantity: this.currentData.verified_quantity
      };
      DemandSupplyStore.rejectSalesDetail({ data: data, id: this.currentData.id }).then(() => {
        this.documentVisible = false;
      });
      this.search();
      this.$notify({
        title: this.$t("subsidyRejected").toString(),
        message: `${this.$t("subsidyRejectedMessage")}`,
        type: "success"
      });
    }
  }

  async created() {
    MetaStore.getAllDistrict();
    MetaStore.getAllFiscalYear();
    DemandSupplyStore.getFyWiseSubsidyCrops({ lang: this.$i18n.locale });
    DemandSupplyStore.getFyWiseSubsidy({ lang: this.$i18n.locale });
    DemandSupplyStore.getAllSeedType();
    this.language = this.$i18n.locale;

    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    if (this.$route.query.cropId) {
      this.cropId = Number(this.$route.query.cropId);
    }
    if (this.$route.query.cropVarietyId) {
      this.cropVarietyId = Number(this.$route.query.cropVarietyId);
    }
    if (this.$route.query.seedTypeId) {
      this.seedTypeId = Number(this.$route.query.seedTypeId);
    }
    if (this.$route.query.status) {
      this.status = String(this.$route.query.status);
    }
    if (this.$route.query.searchCompany) {
      this.searchCompany = String(this.$route.query.searchCompany);
    }
    if (this.$route.query.searchCode) {
      this.searchCode = String(this.$route.query.searchCode);
    }
    let query = this.routerReplacer();

    this.loading = true;
    await DemandSupplyStore.getAdminSupplies(query);
    this.loading = false;
    await UserStore.getUser();

    this.role = JWTStore.role;
  }
}

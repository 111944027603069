




















































































































import { Component, Vue } from "vue-property-decorator";
import CommonStep from "@/views/Authscreen/Subsidy/Steps/CommonStep.vue";
import StepTwo from "@/views/Authscreen/Subsidy/Steps/ImprovedSeedDistributor/StepTwo.vue";
import { ImprovedSeedDistributer, SubsidyDocument } from "@/store/models/user";
import { CropVarietyStore, DemandSupplyStore, MetaStore, UserStore } from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { SubsidyForm } from "@/store/models/crop";
import { getNestedFormData, removeFilesForPatch } from "@/utils/helpers";
import { SubsidySchedule } from "@/store/models/DemandAndSupply";
import { roleChecker } from "@/utils/jwt";

@Component({
  components: {
    CommonStep,
    StepTwo
  },
  metaInfo(): MetaInfo {
    return {
      title: `Subsidy Schedule`,
      meta: [
        {
          name: "description",
          content: "Improved Seed Producer Registration",
          vmid: "registration"
        }
      ]
    };
  }
})
export default class SubsidyScheduleForm extends Vue {
  get subsidySchedule() {
    return DemandSupplyStore.SubsidySchedule;
  }

  set subsidySchedule(schedule: SubsidySchedule) {
    DemandSupplyStore.setSubsidySchedule(schedule);
  }

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  get applicationDeadlineLabel() {
    if (roleChecker(["SSSMAdmin"])) {
      return this.$t("sourceSeedApplicationSubmissionDeadline");
    }
    return this.$t("applicationSubmissionDeadline");
  }

  get applicationEditDeadlineLabel() {
    if (roleChecker(["SSSMAdmin"])) {
      return this.$t("sourceSeedBillSubsimissionDeadline");
    }
    return this.$t("applicationEditDeadline");
  }

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  async submit() {
    let error = false;
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      this.$confirm(
        this.$t("confirmSubsidySchedule").toString(),
        "",
        // this.$t("warning", "Warning").toString(),
        {
          confirmButtonText: this.$t("submit").toString(),
          confirmButtonClass: "el-button--primary",
          cancelButtonText: this.$t("cancelSubmit").toString(),
          showClose: false,
          type: "warning"
        }
      )

        .then(async () => {
          await DemandSupplyStore.updateSubsidySchedule(this.subsidySchedule);
          this.$notify.success("Schedule updated successfully");
          this.$router.push({
            name: "Adminmenu"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("cancelled").toString()
          });
        });
    }
  }

  async reverifyDocument() {
    this.$confirm(
      "This will reset approval of all supplier's documents and all the suppliers will have to reupload their documents. Are you sure?",
      "",
      // this.$t("warning", "Warning").toString(),
      {
        confirmButtonText: this.$t("submit").toString(),
        confirmButtonClass: "el-button--primary",
        cancelButtonText: this.$t("cancelSubmit").toString(),
        showClose: false,
        type: "warning"
      }
    )

      .then(async () => {
        await UserStore.reverifyDocument();
        this.$notify.success("Documents reset successful");
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: this.$t("cancelled").toString()
        });
      });
  }

  async created() {
    await DemandSupplyStore.getSubsidySchedule();
  }
}



























































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { UserStore, JWTStore, MetaStore } from "@/store/modules";
import UserDetailDocument from "@/views/Admin/Components/UserDetailDocument.vue";
import { roleChecker } from "@/utils/jwt";

import DetailTag from "@/components/UIComponents/DetailTag.vue";
import Axios from "axios";
@Component({
  components: {
    DetailTag,
    UserDetailDocument
  },
  metaInfo: {
    title: "User Detail",
    meta: [
      {
        name: "description",
        content: "User Detail",
        vmid: "userdetail"
      }
    ]
  }
})
export default class UserDetail extends Vue {
  userSlug: string = "";
  previous: string = "";

  rejectDialogVisible: boolean = false;
  rejectMessage: string = "";
  detailPdfUrl: string = "";

  get user() {
    return UserStore.UserDetail;
  }

  get user_name() {
    let name = this.user.name;
    if (this.user.nepname) {
      name += ` (${this.user.nepname})`;
    }
    return name;
  }

  get role() {
    return JWTStore.role;
  }

  get approvedStatus() {
    let approved = this.user.approved;
    let value: string = this.$t("pending").toString();
    if (approved == true) {
      value = this.$t("approved").toString();
    } else if (approved == false) {
      value = this.$t("rejected").toString();
    }

    return value;
  }

  get approvalStatusBadgeType() {
    let approved = this.user.approved;
    let value: string = "warning";
    if (approved == true) {
      value = "success";
    } else if (approved == false) {
      value = "danger";
    }
    return value;
  }

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  get_ssc_type(ssc_type: string) {
    const ssc_type_map = {
      samuha: this.$t("farmersGroup").toString(),
      shakari: this.$t("agriCorporative").toString(),
      company: this.$t("company").toString(),
      other: this.$t("other").toString(),
      "Krishak Samuha": this.$t("farmersGroup").toString(),
      "Krishi Sahakari": this.$t("agriCorporative").toString(),
      Company: this.$t("company").toString(),
      Other: this.$t("other").toString()
    };
    if (ssc_type in ssc_type_map) {
      return (ssc_type_map as any)[ssc_type];
    }
    return "";
  }

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  async approve() {
    if (this.user.id != undefined && this.user.id != 0) {
      await UserStore.approveCompanyById({
        id: this.user.id,
        status: true,
        seedtype: this.user.seedtype
      });
      this.$notify.success("Company is being approved. This may take some time.");
    }
  }

  async reject() {
    this.rejectDialogVisible = true;
  }

  deleteUser() {
    let user = this.user;
    this.$confirm(
      "This will permanently delete the company " +
        user.name +
        " and all related data. Do you want to continue?",
      "Warning",
      {
        confirmButtonText: "Delete",
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        showClose: false,
        type: "warning"
      }
    )
      .then(async () => {
        await UserStore.deleteCompanyUserById(user.id);
        this.$notify.success("User deleted");
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "Delete cancelled"
        });
      });
  }

  async sendRejectMessage() {
    this.rejectDialogVisible = false;

    if (this.user.id != undefined && this.user.id != 0) {
      let params = {
        id: this.user.id,
        company_user: this.user.email,
        email_message: this.rejectMessage,
        status: false
      };
      await UserStore.rejectCompanyById(params);
      this.$notify.success("User has been rejected. The rejection mail was sent successfully");
      this.back();
    }
  }

  normal_case(value: string) {
    if (value) {
      let splittedValue = value.split("_");
      let translatedValue = [];
      for (let value of splittedValue) {
        translatedValue.push(this.$t(value).toString());
      }
      value = translatedValue.join(" ");
    }
    return value;
  }

  back() {
    if (this.previous && this.previous != null) {
      this.$router
        .push({
          name: this.previous
        })
        .catch(error => {});
    } else {
      this.$router
        .push({
          name: "Adminmenu"
        })
        .catch(error => {});
    }
  }

  forceFileDownload(response: any) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${this.userSlug}.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
  download() {
    Axios({
      method: "get",
      url: this.detailPdfUrl,
      responseType: "arraybuffer"
    })
      .then((response: any) => {
        this.forceFileDownload(response);
      })
      .catch(() => console.log("error occured"));
  }

  async created() {
    this.userSlug = this.$route.params.userSlug;
    if (this.$route.query.previous && this.$route.query.previous != null) {
      this.previous = this.$route.query.previous as string;
    }
    if (this.userSlug) {
      await UserStore.getCompanyByUserSlug(this.userSlug);
      if (this.user.id) {
        const url = await UserStore.getUserDetailPdf(this.user.id);
        if (url.url) {
          this.detailPdfUrl = url.url;
        }
      }
    }
  }
}
